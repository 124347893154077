import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateCartValue } from '../store/slices/cartSlice';
import { useNavigate } from 'react-router-dom';
import { prepareCartDataForPost } from '../utils';
import Loader from './Loader';
import axios from 'axios';

const CartFooter = () => {
    const [isLoader, setIsLoader] = useState(true);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const cart = useSelector(state => state.cart);

    const cartTotalAmount = cart.items.reduce((cartValue, items) => cartValue + (items.offer_amount * items.quantity), 0);

    useEffect(() => {
        dispatch(updateCartValue(cartTotalAmount));
    }, [dispatch, cartTotalAmount]);

    useEffect(() => {

        const user_mobile = localStorage.getItem('user_mobile');
        const user_name = localStorage.getItem('user_name');

        if (cart.items.length > 0 && user_mobile && user_mobile !== '' && user_name && user_name !== '') {
            var order_item = prepareCartDataForPost(cart);
            addCartAction(user_mobile, user_name, order_item);
        }
    }, [cart, cart.items]);

    const addCartAction = async (mobile, name, orderItem) => {
        setIsLoader(true);
        if (name && mobile && orderItem) {
            const formData = {
                name: name,
                mobile: mobile,
                order_item: orderItem
            }
            try {
                const response = await axios.post('https://ekmcart.com/apis/addCart.php', formData, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                const responseData = response.data;
                if (responseData.success) {
                    console.log("responseData", responseData);
                    setIsLoader(false);
                } else {
                    console.log("responseData", responseData);
                    setIsLoader(false);
                }
            } catch (error) {
                console.log("error", error);
                setIsLoader(false);
            }
        }
    };

    const moveCart = () => {
        navigate(`/cart`);
    }

    if (cart.items.length === 0) {
        return null;
    }

    if (isLoader) {
        return <Loader />
    }

    return (
        <div className=' cart-footer'>
            <div className="container">
                <div className="view-cart-button link" onClick={moveCart}>
                    <div className="view-cart-button_left-content">
                        <b className="block font-norms typography_paragraph !text-lg">{cart.items.length} Items</b>
                        <b className="block font-norms typography_paragraph !text-lg px-1 sm:hidden">|</b>
                        <b className="block font-norms typography_paragraph !text-lg">₹{parseFloat(cart.cartValue).toFixed(2)}</b>
                    </div>

                    <div className="view-cart-button_right-content">
                        <span>
                            <img src="/images/cart.svg" alt="Cart" />
                        </span>
                        <b className="block font-norms typography_paragraph !text-lg !mt-1">View Cart</b>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CartFooter
