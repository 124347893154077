import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { fetchProductData } from '../store/slices/productsSlice';

import Header from '../components/Header'
import Sidebar from '../components/Sidebar'
import CartFooter from '../components/CartFooter'
import Footer from '../components/Footer'
import MobileFooter from '../components/MobileFooter'
import Loader from '../components/Loader';
import ProductItem from '../components/ProductItem';

const ProductPage = () => {
    const dispatch = useDispatch();
    const { cid } = useParams();
    const { data, status } = useSelector(state => state.products);

    useEffect(() => {
        dispatch(fetchProductData(cid))
    }, [cid, dispatch])

    if (status !== "succeeded") {
        return <Loader />;
    }
    return (
        <div className="main-layout_main">

            <Sidebar />

            <div className="product-div page">
                <Header title={"Products"} shown={true} />
                <div className="row">
                    {data &&
                        data.length > 0 &&
                        data.map((item, index) => {
                            return (
                                <ProductItem
                                    key={index}
                                    item={item}
                                    id={item.id}
                                />
                            );
                        })}
                </div>
            </div>

            <CartFooter />

            <Footer />
            <MobileFooter />
        </div>
    )
}

export default ProductPage
