// Dependencies
import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { fetchSiteData } from "./store/slices/siteSlice";
import { fetchSliderData } from "./store/slices/sliderSlice";

// Style
import "./style.css";

// Components
import UserDataCollectPopup from "./components/UserDataCollectPopup";

// Pages
import HomePage from "./pages/HomePage";
import ProductPage from "./pages/ProductPage";
import CartPage from "./pages/CartPage";
import ContactUsPage from "./pages/ContactUsPage";
import PaymentPage from "./pages/PaymentPage";
import OfferPage from "./pages/OfferPage";
import OrderPage from "./pages/OrderPage";
import ProfilePage from "./pages/ProfilePage";
import TermsConditionPage from "./pages/TermsConditionPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import CancellationRefundPage from "./pages/CancellationRefundPage";
import ShippingDeliveryPage from "./pages/ShippingDeliveryPage";


function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchSiteData());
    dispatch(fetchSliderData());
  }, []);

  return (
    <>
      <UserDataCollectPopup />
      <div className="mx-auto bg-skin-base relative">
        <Router>
          <Routes>
            <Route
              path="*"
              element={
                <HomePage />
              }
            />

            <Route
              path="/products/:item/:cid"
              element={
                <ProductPage />
              }
            />

            <Route
              path="/cart"
              element={
                <CartPage />
              }
            />

            <Route
              path="/payment"
              element={
                <PaymentPage />
              }
            />

            <Route
              path="/offer"
              element={
                <OfferPage />
              }
            />

            <Route
              path="/orders/:order_id"
              element={
                <OrderPage />
              }
            />

            <Route
              path="/profile"
              element={
                <ProfilePage />
              }
            />

            <Route
              path="/terms-and-condition"
              element={
                <TermsConditionPage />
              }
            />

            <Route
              path="/privacy-policy"
              element={
                <PrivacyPolicyPage />
              }
            />

            <Route
              path="/cancellation-and-refund"
              element={
                <CancellationRefundPage />
              }
            />

            <Route
              path="/shipping-and-delivery"
              element={
                <ShippingDeliveryPage />
              }
            />

            <Route
              path="/contact-us"
              element={
                <ContactUsPage />
              }
            />
          </Routes>
        </Router>
      </div>
    </>
  );
}

export default App;
