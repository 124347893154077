import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import MobileFooter from '../components/MobileFooter'

const ContactUsPage = () => {
    return (
        <div className="main-layout_main pt-0  page">

            <Header title={"Contact Us"} shown={true} />

            <section className='contact-div'>
                <div className="container min-vh-100">
                    <p>Last updated on Mar 4 2024</p>
                    <div className='card p-2'>
                        <b>You may contact us using the information below:</b>
                        <p>Merchant Legal entity name: <b>DEEPU CLEETUS</b></p>
                        <p>Registered Address: <b>CMRRA 35, Kannanchery House, C Madavan Road, Pallithzham Ernakulam KERALA 683513</b></p>
                        <p>Telephone No: <b>8157990196</b></p>
                        <p>E-Mail ID: <b>neethuraphel1992@gmail.com</b></p>
                    </div>
                </div>
            </section>

            <Footer />
            <MobileFooter />
        </div>
    )
}

export default ContactUsPage
