import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { addItem, removeItem } from '../store/slices/cartSlice';
import axios from 'axios';

import Header from '../components/Header'
import Footer from '../components/Footer'
import MobileFooter from '../components/MobileFooter'
import RefreshPage from './RefreshPage';
import QuantityButtons from '../components/QuantityButtons';
import Loader from '../components/Loader';

import { prepareCartDataForPost } from '../utils';

const CartPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const storeCart = useSelector(state => state.cart);
    const siteData = useSelector(state => state.site.data);

    const [name, setName] = useState('');
    const [mobile, setMobile] = useState('');
    const [house, setHouse] = useState('');
    const [road, setRoad] = useState('');
    const [landmark, setLandmark] = useState('');
    const [location, setLocation] = useState('');
    const [orderItem, setOrderItem] = useState([]);
    const [isLoader, setIsLoader] = useState(true);

    var totalAmount = 0.00;
    var totalOfferAmount = 0.00;

    useEffect(() => {
        const user_mobile = localStorage.getItem('user_mobile');
        const user_name = localStorage.getItem('user_name');

        const address_house = localStorage.getItem('address_house');
        const address_road = localStorage.getItem('address_road');
        const address_landmark = localStorage.getItem('address_landmark');
        const address_location = localStorage.getItem('address_location');

        if (user_name)
            setName(user_name);
        if (user_mobile)
            setMobile(user_mobile);

        if (address_house)
            setHouse(address_house);
        if (address_road)
            setRoad(address_road);
        if (address_landmark)
            setLandmark(address_landmark);
        if (address_location)
            setLocation(address_location);
        setIsLoader(false)
    }, []);

    useEffect(() => {
        if (storeCart && storeCart.items.length > 0) {
            setOrderItem(prepareCartDataForPost(storeCart))
        }
    }, [storeCart])


    const handleName = (e) => {
        setName(e.target.value);
        localStorage.setItem('user_name', e.target.value);
    };

    const handleMobile = (e) => {
        setMobile(e.target.value);
        localStorage.setItem('user_mobile', e.target.value);
    };

    const handleHouse = (e) => {
        setHouse(e.target.value);
        localStorage.setItem('address_house', e.target.value);
    };

    const handleRoad = (e) => {
        setRoad(e.target.value);
        localStorage.setItem('address_road', e.target.value);
    };

    const handleLandmark = (e) => {
        setLandmark(e.target.value);
        localStorage.setItem('address_landmark', e.target.value);
    };

    const handleLocation = (e) => {
        setLocation(e.target.value);
        localStorage.setItem('address_location', e.target.value);
    };

    const handleOnlyDigits = (e) => {
        if (
            !(
                // Digits
                (e.keyCode >= 48 && e.keyCode <= 57) ||
                (e.keyCode >= 96 && e.keyCode <= 105) ||
                // Control keys
                e.keyCode === 8 || // Backspace
                e.keyCode === 46 || // Delete
                e.keyCode === 9 || // Tab
                e.keyCode === 13 // Enter
            )
        ) {
            e.preventDefault();
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoader(true);
        if (name !== '' && mobile !== '' && mobile.length === 10 && location !== '' && road !== '') {
            const formData = {
                name: name,
                mobile: mobile,
                address_house: house,
                address_road: road,
                address_landmark: landmark,
                address_location: location,
                order_item: orderItem
            }

            console.log(formData);

            try {
                const response = await axios.post('https://ekmcart.com/apis/confirmCart.php', formData, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                const responseData = response.data;
                if (responseData.success) {
                    localStorage.setItem('last_order', JSON.stringify(responseData.storedData));
                    navigate('/payment');
                } else {
                    console.log("responseData", responseData);
                }
            } catch (error) {
                console.log("error", error);
            }
        }
    };

    const handleAddToCart = (item) => {
        dispatch(addItem(item));
    };
    const handleRemoveFromCart = (itemId) => {
        dispatch(removeItem(itemId));
    };

    if (storeCart.items.length === 0) {
        return <RefreshPage />
    }
    if (isLoader) {
        return <Loader />
    }
    return (
        <>
            <section className="mb-5 cart page">
                <Header title={"Cart"} shown={true} />
                <div className='container'>
                    <h5>Cart ({storeCart.items.length} Items )</h5>
                    <div className="cart-div">
                        <div className="card cart-list">
                            {
                                storeCart.items.map(item => {
                                    totalAmount = totalAmount + item.totalAmount;
                                    totalOfferAmount = totalOfferAmount + item.totalOfferAmount;

                                    return (
                                        <div key={item.id} className="item-row">
                                            <div className="">
                                                {/* col-3  d-flex justify-content-center items-center */}
                                                <img
                                                    src={item.main_image_url}
                                                    alt={item.title}
                                                    className="cart-item-image"
                                                />
                                            </div>
                                            <div className="cart-items_item-text-container d-flex flex-column">
                                                <label className="cart-item-label">{item.name}</label>
                                                <label className="cart-item-qty">{item.qty}</label>
                                            </div>

                                            <div className="cart-items_btn-price-container">
                                                <QuantityButtons
                                                    product={item}
                                                    // className="!w-16"
                                                    handleAddToCart={handleAddToCart}
                                                    handleRemoveFromCart={handleRemoveFromCart}
                                                />

                                                <div className="text-right pl-2 pr-2">
                                                    <b className='block offer-price typography_paragraph'>{"₹" + parseFloat(item.totalOfferAmount).toFixed(2)}</b>

                                                    {item.offer_amount !== item.amount ? (
                                                        <del className='block org-price typography_paragraph'> {"₹" + parseFloat(item.totalAmount).toFixed(2)}</del>
                                                    ) : (<p></p>)}

                                                </div>
                                            </div>

                                        </div>
                                    )
                                })}
                        </div>
                    </div>
                    <div className="cart-div">

                        <div className="card p-3">
                            <div className="row pb-3">
                                <div className="col-8 my-2">
                                    <b>Order Amount</b>
                                </div>
                                <div className="col-4 my-2 text-end">
                                    <b>₹ {parseFloat(totalAmount).toFixed(2)}</b>
                                </div>

                                <div className="col-8 my-2">
                                    <b>You Saved Amount</b>
                                </div>
                                <div className="col-4 my-2 text-end">
                                    <b>₹ {parseFloat(totalAmount - totalOfferAmount).toFixed(2)}</b>
                                </div>

                                <div className="col-8 my-2">
                                    <b>Delivery Charge</b>
                                </div>
                                <div className="col-4 my-2 text-end">
                                    <b>₹ {parseFloat(siteData.DELIVERY_CHARGE).toFixed(2)}</b>
                                </div>


                                <div className="col-8 my-2 text-primary">
                                    <b>Total Amount</b>
                                </div>
                                <div className="col-4 my-2 text-end text-primary">
                                    <b>₹ {parseFloat(totalOfferAmount + parseInt(siteData.DELIVERY_CHARGE)).toFixed(2)}</b>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card p-3 address-div">
                        <h5>Delivery Address</h5>
                        <div className="row">
                            <div className="col-xs-12 col-sm-6 input-div">
                                <label className='form-label'>Name</label>
                                <input
                                    type="text"
                                    name="name"
                                    className='form-control'
                                    value={name}
                                    onChange={handleName}
                                    required
                                />
                            </div>
                            <div className="col-xs-12 col-sm-6 input-div">
                                <label className='form-label'> Mobile Number</label>
                                <input
                                    type="text"
                                    name="mobile"
                                    className='form-control'
                                    minLength={10}
                                    value={mobile}
                                    onChange={handleMobile}
                                    onKeyDown={handleOnlyDigits}
                                    required
                                />
                            </div>

                            <div className="col-12 input-div">
                                <label className='form-label'>House Number</label>
                                <input
                                    type="text"
                                    name="address_house"
                                    className='form-control'
                                    value={house}
                                    onChange={handleHouse}
                                />
                            </div>

                            <div className="col-12 input-div">
                                <label className='form-label'>Road</label>
                                <input
                                    type="text"
                                    name="address_house"
                                    className='form-control'
                                    onChange={handleRoad}
                                    value={road}
                                    required
                                />
                            </div>

                            <div className="col-12 input-div">
                                <label className='form-label'>Landmark</label>
                                <input
                                    type="text"
                                    name="address_landmark"
                                    className='form-control'
                                    onChange={handleLandmark}
                                    value={landmark}
                                />
                            </div>

                            <div className="col-12 input-div">
                                <label className='form-label'>Location</label>
                                <input
                                    type="text"
                                    name="address_location"
                                    className='form-control'
                                    onChange={handleLocation}
                                    value={location}
                                    required
                                />
                            </div>

                            <div className="col-12 mt-3 d-grid gap-2">
                                <button onClick={handleSubmit} className="btn  btn-success">Confirm Order</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
            <MobileFooter />
        </>
    )
}

export default CartPage
