import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { fetchCategoryData } from './../store/slices/categoriesSlice';
import { useNavigate } from 'react-router-dom';
import { createSlug } from './../hooks/helper';

const Sidebar = () => {
  const dispatch = useDispatch();
  const { data } = useSelector(state => state.categories);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchCategoryData())
  }, [dispatch])

  const moveProducts = (categoryKey, cid) => {
    navigate(`/products/${categoryKey}/${cid}`);
  }

  return (
    <nav className={`sidebar close`}>
      <header onClick={() => navigate("/")}>
        <div className="logo-heading image-text">
          <span className="image">
            <img className="img-md" alt="EC" src="https://imagedelivery.net/orRO5QD-gkKEU1hEFUW22A/1a6bf0a6-6ea8-4d77-553b-b1592e950900/public" />
            {/* <span className="name">EC</span> */}
          </span>
          <div className="text logo-text">
            <span className="name">EKM-CART</span>
            <span className="profession">Ernakulam Cart</span>
          </div>
        </div>
      </header>
      <div className="menu-bar">
        <div className="menu">
          <div key="offered_item" onClick={() => navigate("/offers")} className="cat-icon sub-cat-sb-item">
            <div className="sub-cat-img-item">
              <div className="sub-cat-img-cont">
                <img className="sub-cat-img" alt="offered_item" src="https://imagedelivery.net/orRO5QD-gkKEU1hEFUW22A/b4dd913b-e2d7-4319-aa95-7a1a55073100/public" />
              </div>
            </div>
            {/* <p className="sub-cat-label">Offers</p> */}
          </div>
          {data &&
            data.length > 0 &&
            data.map(category => (
              <div key={category.id} onClick={() => moveProducts(createSlug(category.name), category.id)} className="cat-icon sub-cat-sb-item">
                <div className="sub-cat-img-item">
                  <div className="sub-cat-img-cont">
                    <img className="sub-cat-img" alt={category.name} src={category.image} />
                  </div>
                </div>
                {/* <p className="sub-cat-label">{category.name}</p> */}
              </div>
            ))
          }
        </div>
      </div>
    </nav>
  );
};
export default Sidebar;
