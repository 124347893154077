import React from 'react'

const MobileFooter = () => {
    return (
        <footer className="mob-footer d-block d-md-none mt-3">
            <div className="py-3 w-100 flex flex-nowrap my-6 justify-content-around align-top">
                <div>Home</div>
                <div>Cart</div>
                <div>Account</div>
            </div>
        </footer>
    )
}

export default MobileFooter
