import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// Define the initial state for the data slice
const initialState = {
  data: [],
  status: "idle",
  error: null,
};

export const fetchSiteData = createAsyncThunk(
  "site/fetchSiteData",
  async () => {
    const url = `https://ekmcart.com/apis/getSiteData.php`;
    try {
      const response = await fetch(url, {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  }
);

// Create a slice using createSlice
const siteSlice = createSlice({
  name: "site",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSiteData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchSiteData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(fetchSiteData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

// Export the reducer
export default siteSlice.reducer;
