import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { getOrderData } from '../hooks/helper';

import Header from '../components/Header'
import Footer from '../components/Footer'
import MobileFooter from '../components/MobileFooter'
import Loader from '../components/Loader';

const OrderPage = () => {
    const { order_id } = useParams();
    const [orderData, setOrderData] = useState([])
    useEffect(() => {
        const fetchDataFromApi = async () => {
            try {
                const data = await getOrderData(order_id);
                setOrderData(data);
            } catch (error) {
                console.error(error);
            }
        };
        fetchDataFromApi();
        return () => {
            setOrderData([]);
        };
    }, [order_id]);

    if (orderData.length === 0) {
        return <Loader />;
    }
    return (
        <div className="main-layout_main pt-0">

            <section className="cart page">
                <Header title={"My Orders"} shown={true} />
                <div className='container'>
                    <div className="cart-div">
                        <h5>Order</h5>
                        <div className="card">
                            <div className="row pb-3">
                                <div className="col-6">
                                    <b>Order ID</b>
                                </div>
                                <div className="col-6">
                                    <b>{orderData.order.id}</b>
                                </div>

                                <div className="col-6">
                                    <b>Amount</b>
                                </div>
                                <div className="col-6">
                                    <b>₹ {parseFloat(orderData.order.amount).toFixed(2)}</b>
                                </div>

                                <div className="col-6">
                                    <b>Order Status</b>
                                </div>
                                <div className="col-6">
                                    <b>{orderData.order.order_status}</b>
                                </div>

                                <div className="col-6">
                                    <b>Order Date</b>
                                </div>
                                <div className="col-6">
                                    <b>{orderData.order.order_date}</b>
                                </div>

                                <div className="col-6">
                                    <b>Payment Status</b>
                                </div>
                                <div className="col-6">
                                    <b>{orderData.order.payment_status}</b>
                                </div>

                                <div className="col-6">
                                    <b>Payment Date</b>
                                </div>
                                <div className="col-6">
                                    <b>{orderData.order.payment_date}</b>
                                </div>

                                <div className="col-6">
                                    <b>Payment Mode</b>
                                </div>
                                <div className="col-6">
                                    <b>{orderData.order.payment_mode}</b>
                                </div>

                                <div className="col-6">
                                    <b>Payment Reference</b>
                                </div>
                                <div className="col-6">
                                    <b>{orderData.order.payment_reference}</b>
                                </div>

                                <div className="col-6">
                                    <b>Remark</b>
                                </div>
                                <div className="col-6">
                                    <b>{orderData.order.remark}</b>
                                </div>


                                <div className="col-6">
                                    <b>You Saved Amount</b>
                                </div>
                                <div className="col-6">
                                    <b>{orderData.order.you_save !== null ? "₹ " + parseFloat(orderData.order.you_save).toFixed(2) : ''}</b>
                                </div>

                            </div>
                        </div>
                    </div>


                    <div className="cart-div">
                        <h5>Order Items</h5>
                        <div className="card cart-list">
                            {orderData.order_item.map(item => {
                                console.log(item)
                                return (
                                    <div key={item.id} className="item-row">
                                        <div className="">
                                            <img
                                                src={item.product_img}
                                                alt={item.product_name}
                                                className="cart-item-image"
                                            />
                                        </div>
                                        <div className="cart-items_item-text-container d-flex flex-column">
                                            <label className="cart-item-label">{item.product_name}</label>
                                            <label className="cart-item-qty">{item.product_qty}</label>
                                        </div>

                                        <div className="cart-items_btn-price-container">
                                            <div className="text-left pl-2 pr-2">
                                                <b className='block offer-price typography_paragraph'>{item.quantity}</b>
                                            </div>

                                            <div className="text-right pl-2 pr-2">
                                                <b className='block offer-price typography_paragraph'>{"₹" + parseFloat(item.sub_amount).toFixed(2)}</b>
                                            </div>
                                        </div>

                                    </div>
                                )
                            })}
                        </div>
                    </div>

                    <div className="address-div">
                        <h5>Delivery Address</h5>
                        <div className="row">
                            <div className="col-6">
                                <label className='form-label'>Name</label>
                            </div>
                            <div className="col-6">
                                <label className='form-label'>{orderData.order.name}</label>
                            </div>


                            <div className="col-6">
                                <label className='form-label'> Mobile Number</label>
                            </div>
                            <div className="col-6">
                                <label className='form-label'>{orderData.order.mobile}</label>
                            </div>

                            <div className="col-6">
                                <label className='form-label'>House Number</label>
                            </div>
                            <div className="col-6">
                                <label className='form-label'>{orderData.order.address_house}</label>
                            </div>

                            <div className="col-6">
                                <label className='form-label'>Road</label>
                            </div>
                            <div className="col-6">
                                <label className='form-label'>{orderData.order.address_road}</label>
                            </div>

                            <div className="col-6">
                                <label className='form-label'>Landmark</label>
                            </div>
                            <div className="col-6">
                                <label className='form-label'>{orderData.order.address_landmark}</label>
                            </div>

                            <div className="col-6">
                                <label className='form-label'>Location</label>
                            </div>
                            <div className="col-6">
                                <label className='form-label'>{orderData.order.address_location}</label>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <Footer />
            <MobileFooter />
        </div>
    )
}

export default OrderPage
