import React from 'react'
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const CarouselSlider = ({ sliders }) => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 3500,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3500,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            }
        ]
    };

    return (
        <div className="pt-[0.75rem]" style={{ marginBottom: 'calc(-0.375rem)', marginTop: '-0.75rem' }}>
            <div className="slider_slider-wrapper">
                <Slider {...settings} >
                    {sliders && sliders.map((data, index) => (
                        <div key={index}>
                            <img
                                className="d-block w-100"
                                src={data.image_url}
                                alt={data.label}
                            />
                        </div>))}
                    {/* <div>
                        <img
                            className="d-block w-100"
                            src="https://imagedelivery.net/orRO5QD-gkKEU1hEFUW22A/1abb51d7-b7f5-4dbf-8f71-8dc720bda800/public"
                            alt="slider 2"
                        />
                    </div>
                    <div>
                        <img
                            className="d-block w-100"
                            src="https://imagedelivery.net/orRO5QD-gkKEU1hEFUW22A/9b2ffdc6-196c-4f82-40b5-80389e8b6300/public"
                            alt="slider 3"
                        />
                    </div> */}
                    {/* Add more slides as needed */}
                </Slider>
            </div>
        </div>

    )
}

export default CarouselSlider 
