import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import MobileFooter from '../components/MobileFooter'

const CancellationRefundPage = () => {
    return (
        <div className="main-layout_main pt-0  page">

            <Header title={"Cancellation"} shown={true} />

            <section className='cancel-div'>
                <div className="container min-vh-100">
                    <p>Last updated on Mar 4 2024</p>
                    <b>No cancellations & Refunds are entertained</b>
                </div>
            </section>

            <Footer />
            <MobileFooter />
        </div>
    )
}

export default CancellationRefundPage
