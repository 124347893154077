import React from 'react'
import { useNavigate } from 'react-router-dom';

const RefreshPage = () => {
    const navigate = useNavigate();
    return (
        <div className='container refresh-pg'>
            <div className="d-flex flex-column justify-content-center align-items-center text-center">

                <b>Your cart is empty</b>

                <button className='relative rounded quantity_buttons_boarded add_cart' onClick={() => navigate("/")}>Browse Products</button>
            </div>
        </div>
    )
}

export default RefreshPage
