import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import MobileFooter from '../components/MobileFooter'

const ShippingDeliveryPage = () => {
    return (
        <div className="main-layout_main pt-0  page">

            <Header title={"Shipping"} shown={true} />

            <section className='shipping-div'>
                <div className="container min-vh-100">
                    <p>Last updated on Mar 4 2024</p>
                    <div className='card p-2'>
                        <b>You may contact us using the information below:</b>
                        <p>Shipped and delivered are limited to the municipal area, North paravur Municipality, Ernakulam. For domestic buyers, orders are shipped through registered domestic courier companies and /or speed post only. Orders are shipped within 0-7 days or as per the delivery date agreed at the time of order confirmation and delivering of the shipment subject to Courier Company / post office norms. DEEPU CLEETUS is not liable for any delay in delivery by the courier company / postal authorities and only guarantees to hand over the consignment to the courier company or postal authorities within 0-7 days rom the date of the order and payment or as per the delivery date agreed at the time of order confirmation. Delivery of all orders will be to the address provided by the buyer. Delivery of our services will be confirmed on your mail ID as specified during registration. For any issues in utilizing our services you may contact our helpdesk on 8157990196 or neethuraphel1992@gmail.com</p>
                    </div>
                </div>
            </section>

            <Footer />
            <MobileFooter />
        </div>
    )
}

export default ShippingDeliveryPage
