import React from 'react'
import Header from '../components/Header'
import Sidebar from '../components/Sidebar'
import Footer from '../components/Footer'
import MobileFooter from '../components/MobileFooter'

const OfferPage = () => {
    return (
        <div className="main-layout_main pt-0  page">

            <Header title={"Offers"} shown={true} />

            <Sidebar />

            <div>
                offers
            </div>

            <Footer />
            <MobileFooter />
        </div>
    )
}

export default OfferPage
