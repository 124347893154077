export const createSlug = (input) => {
  return input
    .toLowerCase() // Convert the input to lowercase
    .replace(/\s+/g, "-") // Replace spaces with hyphens
    .replace(/[^\w\-]+/g, "") // Remove non-word characters except hyphens
    .replace(/\-\-+/g, "-") // Replace multiple hyphens with a single hyphen
    .replace(/^-+/, "") // Remove leading hyphens
    .replace(/-+$/, ""); // Remove trailing hyphens
};

export const getOrderData = async (order_id) => {
  const url = `https://ekmcart.com/apis/getOrderData.php?order_id=${order_id}`;
  console.log(url);
  try {
    const response = await fetch(url, {
      method: "GET",
      mode: "cors", // This sets the CORS mode
      headers: {
        "Content-Type": "application/json", // Adjust content type if needed
        // Add any other headers as needed
      },
    });
    const responseData = await response.json();
    if (responseData.success) {
      return responseData.storedData;
    } else {
      console.log("responseData", responseData);
      return [];
    }
  } catch (error) {
    throw new Error("Error fetching data:", error);
  }
};
