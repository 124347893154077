import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import MobileFooter from '../components/MobileFooter'

const ProfilePage = () => {
    return (
        <div className="main-layout_main pt-0  page">

            <Header title={"Profile"} shown={true} />

            <div>
                My Profile
            </div>

            <Footer />
            <MobileFooter />
        </div>
    )
}

export default ProfilePage
