import { configureStore } from "@reduxjs/toolkit";
import productsReducer from "./slices/productsSlice";
import categoriesReducer from "./slices/categoriesSlice";
import subCategoriesReducer from "./slices/subCategoriesSlice";
import cartReducer from "./slices/cartSlice";
import siteReducer from "./slices/siteSlice";
import sliderReducer from "./slices/sliderSlice";

import { combineReducers } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  products: productsReducer,
  categories: categoriesReducer,
  subCategories: subCategoriesReducer,
  cart: cartReducer,
  site: siteReducer,
  slider: sliderReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);
