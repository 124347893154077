import React from 'react'
import { useSelector } from 'react-redux';
// import { addItem, removeItem } from '../store/slices/cartSlice';

const QuantityButtons = (props) => {
    const items = useSelector(state => state.cart.items.find(cItem => cItem.id === props.product.id));

    // const dispatch = useDispatch();

    // const handleAddToCart = (item) => {
    //     dispatch(addItem(item));
    // };
    // const handleRemoveFromCart = (itemId) => {
    //     dispatch(removeItem(itemId));
    // };

    return (
        items ? (
            <div className={['flex', 'relative', 'quantity_button_controls', 'rounded', `${props.className}`].join(" ")}>

                <div className="flex justify-center items-center quantity_buttons border-skin-primary text-lg" onClick={() => props.handleRemoveFromCart(items.id)}> <i className="bi bi-dash"></i> </div>

                <div className="flex justify-center items-center quantity_text text-skin-base cart-item-qty">{items.quantity}</div>

                <div className="flex justify-center items-center quantity_buttons border-skin-primary text-lg" onClick={() => props.handleAddToCart(items)}>
                    <i className="bi bi-plus"></i>
                </div>
            </div>
        ) : (
            <div className="w-100  items-center text-center relative rounded quantity_add_btn ">
                <div className="add_cart text-md" onClick={() => props.handleAddToCart(props.product)}> ADD </div>
            </div>
        )
    )
}

export default QuantityButtons
