import React from "react";
import { useDispatch } from 'react-redux';
import { addItem, removeItem } from '../store/slices/cartSlice';
import QuantityButtons from "./QuantityButtons";
import { checkString75 } from "../utils";

const ProductItem = ({ item }) => {
  const dispatch = useDispatch();

  const handleAddToCart = (item) => {
    dispatch(addItem(item));
  };
  const handleRemoveFromCart = (itemId) => {
    dispatch(removeItem(itemId));
  };

  return (
    <div id={item.id} key={item.id} className="col-6 col-sm-6 col-md-4 col-lg-2 card cat-item-card p-item p-1 d-flex justify-content-between">
      {item.offer_amount !== item.amount ? (
        <div className="top-off-div">
          {(((item.amount - item.offer_amount) / item.amount) * 100).toFixed(0)}% Off
        </div>
      ) : null}

      <div className="relative product-card_product-image-container">
        <img
          src={item.main_image_url}
          alt={item.name}
          className="card-img-top rounded img-fluid p-2"
        />
      </div>

      <div className="item-desc-box">
        <label className="item-label">{checkString75(item.name)}</label>
        {item.ml_name !== '' ? (<label className="item-label">{checkString75(item.ml_name)}</label>) : null}
      </div>

      <div className="d-flex justify-content-between text-center">
        {item.offer_amount !== item.amount ? (<del className="org-price">₹ {parseFloat(item.amount).toFixed(2)}</del>) : (<p></p>)}
        <b className="text-success offer-prize">₹ {parseFloat(item.offer_amount).toFixed(2)}</b>

      </div>

      <div className="d-flex align-items-end justify-content-around">
        <QuantityButtons product={item} handleAddToCart={handleAddToCart} handleRemoveFromCart={handleRemoveFromCart} />
      </div>
    </div>
  );
};

export default ProductItem;
