import React from 'react'
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const Footer = () => {
    const navigate = useNavigate();
    const siteData = useSelector(state => state.site.data);
    return (
        <footer className="footer d-none d-md-block mt-3 mb-2">

            <div className="border-gray-100 border"></div>

            <div className="p-3 flex flex-wrap justify-content-around  align-top" >

                {/* Icons Logo */}
                <div className="flex flex-column">

                    <img onClick={() => navigate("/")} alt="" width="105" height="75" className="link relative overflow-hidden false " src="https://imagedelivery.net/orRO5QD-gkKEU1hEFUW22A/6084db6a-8bc1-404a-e44e-8f9885322a00/public" />

                    <div className="flex justify-content-between flex-row mt-3 !ml-0 ">

                        <a target="_blank" href="https://www.instagram.com/ekmcart/" className="link items-end w-fit " rel="noreferrer" >

                            <img alt="" width="22" height="22" className="relative overflow-hidden w-fit !h-[2.375rem]" src="/images/instagram.svg" />

                        </a>

                        <a target="_blank" href="https://twitter.com/ekmcart" className="link items-end w-fit" rel="noreferrer">

                            <img alt="" width="22" height="22" className="relative overflow-hidden false w-fit !h-[2.375rem]" src="/images/twitter.svg" />

                        </a>

                        <a target="_blank" href="https://www.facebook.com/ekmcart/" className="link items-end w-fit" rel="noreferrer" >

                            <img alt="" width="22" height="22" className="relative overflow-hidden false w-fit !h-[2.375rem]" src="/images/facebook.svg" />

                        </a>

                        <a target="_blank" href={`https://api.whatsapp.com/send/?phone=${siteData.WHATSAPP_NUMBER}`} className="link items-end w-fit" rel="noreferrer">
                            <img alt="" width="22" height="22" className="relative overflow-hidden false w-fit !h-[2.375rem]" src="/images/whatsapp.svg"
                            />
                        </a>
                    </div>

                    <p className="block font-norms typography_paragraph mt-4 text-gray-400 !font-subtitle d-flex flex-wrap" >
                        © EKM Cart Technologies Private Limited
                    </p>
                </div>

                {/* Links */}

                <div className="xs-hidden d-flex flex-column justify-content-end ">

                    <label className='link !font-subtitle mt-2' onClick={() => navigate("/privacy-policy")}>
                        Privacy Policy
                    </label>

                    <label className='link !font-subtitle mt-2' onClick={() => navigate("/terms-and-condition")}>
                        Terms and Conditions
                    </label>

                    <label className='link !font-subtitle mt-2' onClick={() => navigate("/cancellation-and-refund")}>
                        Cancellation and Refund
                    </label>

                    <label className='link !font-subtitle mt-2' onClick={() => navigate("/shipping-and-delivery")}>
                        Shipping and Delivery
                    </label>

                    <label className='link !font-subtitle mt-2' onClick={() => navigate("/contact-us")}>
                        Contact Us
                    </label>
                </div>

                {/* Apps */}

                {/* <div className="flex flex-col basis-1/4 lg:basis-1/6 mt-5 sm:mt-0">
                    <p className="block font-norms typography_paragraph__I83Dc   !font-subtitle">
                        Download App
                    </p>

                    <div className="flex flex-col !snap-center w-full sm:w-[13.75rem]">
                        <button
                            className="flex flex-row border border-gray-300 rounded-md mt-3 py-3.5 !px-7 justify-center w-full">
                            <img alt="" width="2" height="2" className="relative overflow-hidden false w-3.5 mx-2 min-w-min" src="https://cdn.zeptonow.com/web-static-assets-prod/artifacts/9.1.1/images/app-stores/play-store.svg" />

                            <p className="block font-norms typography_paragraph-medium__m1w1_ !font-subtitle">
                                Get it on play store
                            </p>
                        </button>
                        
                        <button
                            className="flex flex-row border border-gray-300 rounded-md mt-3 py-3.5 !px-7 justify-center w-full">
                            <img alt="" width="2" height="2" className="relative overflow-hidden false w-3.5 mx-2 min-w-min" src="https://cdn.zeptonow.com/web-static-assets-prod/artifacts/9.1.1/images/app-stores/app-store.svg" />
                            <p className="block font-norms typography_paragraph-medium__m1w1_ !font-subtitle">
                                Get it on app store
                            </p>
                        </button>

                    </div>
                </div> */}

            </div>
        </footer>
    )
}

export default Footer
