import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// Define the initial state for the data slice
const initialState = {
  data: [],
  status: "idle",
  error: null,
};

// Define the asynchronous thunk to fetch data from the API
export const fetchSubCategoryData = createAsyncThunk(
  "categories/fetchSubCategoryData",
  async (parentId) => {
    // const url = `/db/categories.json`;
    const url = `https://ekmcart.com/apis/getSubCategories.php?parent_id=${parentId}`;
    try {
      const response = await fetch(url, {
        method: "GET",
        mode: "cors", // This sets the CORS mode
        headers: {
          "Content-Type": "application/json", // Adjust content type if needed
          // Add any other headers as needed
        },
      });

      const data = await response.json();
      return data;
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  }
);

// Create a slice using createSlice
const categoriesSlice = createSlice({
  name: "subCategories",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSubCategoryData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchSubCategoryData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(fetchSubCategoryData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

// Export the reducer
export default categoriesSlice.reducer;
