import React from 'react';
import { ThreeCircles } from 'react-loader-spinner'

const Loader = () => {
    return (
        <div className="loader-container">
            <ThreeCircles
                visible={true}
                height="100"
                width="100"
                color="rgb(255 50 105)"
                ariaLabel="three-circles-loading"
                wrapperStyle={{}}
                wrapperClass=""
            />
        </div>
    );
};

export default Loader;
