import React from 'react'

const Banner = () => {
    return (
        <section className="mt-1 relative">
            <div className=" w-full d-sm-none">
                <img alt="banner_desk" className="relative overflow-hidden w-full " src="https://imagedelivery.net/orRO5QD-gkKEU1hEFUW22A/40687ac7-4e90-417e-7248-b21edce7a300/public" style={{ color: 'transparent', objectFit: 'fill' }} />
            </div>
            <div className=" w-full d-none d-sm-block">
                <img alt="banner_mob" className="relative overflow-hidden " src="https://imagedelivery.net/orRO5QD-gkKEU1hEFUW22A/22c1f1fa-2bcd-4d8a-c17b-1473ec9c7700/public" style={{ color: 'transparent', objectFit: 'fill' }} />
            </div>
        </section>
    )
}

export default Banner
