import React from 'react'
import { useNavigate } from 'react-router-dom';

const Header = (props) => {
    const navigate = useNavigate();
    const moveBack = () => {
        navigate(-1);
    }
    return (
        <>
            {/* Desktop */}
            {props.shown === true ? (
                <nav className="d-none d-md-block header_header  mb-3">
                    <div className="d-flex justify-content-between flex-row">
                        <div className="d-flex flex-row">
                            <button className="!bg-transparent py-1 px-7 text-base button_btn-text border-skin-primary !px-0 mr-2" aria-label="Back Button">
                                <div className="flex justify-center items-center font-lato" onClick={(moveBack)}>
                                    <i className="bi bi-chevron-double-left"></i>
                                </div>
                            </button>
                            <h1 className="header_title ">{props.title}</h1>
                        </div>
                        <div className="d-flex justify-content-between gap-3 flex-row">

                            <button className="!bg-transparent py-1 px-7 text-base button_btn-text border-skin-primary !px-0 mr-2" aria-label="Home" onClick={() => navigate(`/`)}>
                                Home
                            </button>
                            {" | "}
                            <button className="!bg-transparent py-1 px-7 text-base button_btn-text border-skin-primary !px-0 mr-2"
                                onClick={() => navigate(`/cart`)}>
                                Cart
                            </button>
                            {" | "}
                            <button className="!bg-transparent py-1 px-7 text-base button_btn-text border-skin-primary !px-0 mr-2" onClick={() => navigate(`/profile`)}>
                                Account
                            </button>
                        </div>

                    </div>
                </nav>
            ) : null}
            {/* Mobile */}
            <nav className="d-block d-md-none header_header mb-3">
                <div className="d-flex justify-content-between flex-row">
                    <button className="!bg-transparent py-1 px-7 text-base button_btn-text border-skin-primary !px-0 mr-2" aria-label="Back Button">
                        <div className="flex justify-center items-center font-lato" onClick={(moveBack)}>
                            {/* <span className="mr-2"> */}
                            <i className="bi bi-chevron-double-left"></i>
                            {/* </span> */}
                        </div>
                    </button>
                    <h1 className="header_title ">{props.title}</h1>
                </div>
            </nav>
        </>
    )
}

export default Header
