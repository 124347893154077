import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { fetchCategoryData } from '../store/slices/categoriesSlice';
import { useNavigate } from 'react-router-dom';
import { createSlug } from '../hooks/helper';

import MobileFooter from "../components/MobileFooter";
import Footer from "../components/Footer";
import CartFooter from "../components/CartFooter";
import Banner from "../components/Banner";
import CarouselSlider from "../components/Slider";
import Loader from "../components/Loader";

const HomePage = () => {

  const dispatch = useDispatch();
  const { data, status } = useSelector(state => state.categories);
  const siteData = useSelector(state => state.site.data);
  const sliders = useSelector(state => state.slider.data);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchCategoryData())
  }, [dispatch])

  const moveCategory = (categoryKey, cid) => {
    navigate(`/products/${categoryKey}/${cid}`);
  }
  const customerCareWhatsapp = (number) => {
    const url = `https://web.whatsapp.com/send?phone=${number}`;
    window.open(url);
  }

  if (status !== "succeeded") {
    return <Loader />;
  }

  return (
    <div className="main-layout_main pt-0">
      <Banner />
      <CarouselSlider sliders={sliders} />

      <div style={{ marginBottom: '5rem', minHeight: 'calc(100%)' }}>
        <div className="category-grid_container">

          <div className="category-grid_header !mb-0">

            <h4 className="block font-norms typography_h4  md:!text-[1.25rem] md:!leading-7" style={{ color: 'rgb(38, 42, 51)' }}>Explore By Categories</h4>

            <div onClick={() => customerCareWhatsapp(siteData.WHATSAPP_NUMBER)} className="link block font-norms typography_h4  md:!text-[1.25rem] md:!leading-7" style={{ color: 'rgb(0, 0, 0)' }}>
              <i className="bi bi-whatsapp"></i> വാട്സാപ്പിൽ ഓർഡർ ചെയ്യാം
            </div>
          </div >

          <div div className="category-grid_content pt-4 row" >

            <div key={"offered_item"} id={"offered_item"} onClick={() => navigate("/offers")} className="category-grid_withname-small">
              <img alt="profile" src="https://imagedelivery.net/orRO5QD-gkKEU1hEFUW22A/b4dd913b-e2d7-4319-aa95-7a1a55073100/public" className="relative overflow-hidden rounded-lg home-cat-image" style={{ color: 'transparent', objectFit: 'contain' }} />
              <p className="sub-cat-label">Offers</p>
            </div>

            {
              data &&
              data.length > 0 &&
              data.map(category => (
                <div key={category.id} id={category.id} onClick={() => moveCategory(createSlug(category.name), category.id)} className="category-grid_withname-small">
                  <img alt={category.name} src={category.image} className="relative overflow-hidden rounded-lg home-cat-image" style={{ color: 'transparent', objectFit: 'contain' }} />
                  {/* <p className="sub-cat-label">{category.name}</p> */}
                </div>
              )
              )
            }

            <div key={"profile"} id={"profile"} onClick={() => navigate("/profile")} className="category-grid_withname-small">
              <img alt="profile" src="https://imagedelivery.net/orRO5QD-gkKEU1hEFUW22A/7de08525-c9b2-45df-5ed6-8c0bee85ee00/public" className="relative overflow-hidden rounded-lg home-cat-image" style={{ color: 'transparent', objectFit: 'contain' }} />
              {/* <p className="sub-cat-label">My Profile</p> */}
            </div>

            <div key={"customer_care"} id={"customer_care"} onClick={() => customerCareWhatsapp(siteData.WHATSAPP_NUMBER)} className="category-grid_withname-small">
              <img alt="profile" src="https://imagedelivery.net/orRO5QD-gkKEU1hEFUW22A/eb7e3f96-38fc-49ad-cefa-34b812574500/public" className="relative overflow-hidden rounded-lg home-cat-image" style={{ color: 'transparent', objectFit: 'contain' }} />
              <p className="sub-cat-label">Customer Care</p>
            </div>
          </div >
        </div >
      </div>

      <CartFooter />

      <Footer />
      <MobileFooter />
    </div>

  );
};
export default HomePage;
