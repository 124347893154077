import React, { useEffect, useState } from 'react'

const UserDataCollectPopup = () => {
    const [showPopup, setShowPopup] = useState(false);
    const [userName, setUserName] = useState('');
    const [userMobile, setUserMobile] = useState('');

    useEffect(() => {
        const user_mobile = localStorage.getItem('user_mobile');
        const user_name = localStorage.getItem('user_name');

        if (!user_mobile || !user_name) {
            const timeoutId = setTimeout(() => {
                setShowPopup(true);
            }, 30000); // Show popup after 30 seconds

            return () => clearTimeout(timeoutId);
        }
    }, []); // Empty dependency array ensures this effect runs only once on component mount

    useEffect(() => {
        if (showPopup) {
            const timeoutId = setTimeout(() => {
                // Show the popup again after 2 minutes if the user closes it
                setShowPopup(true);
            }, 120000); // Show popup again after 2 minutes

            return () => clearTimeout(timeoutId);
        }
    }, [showPopup]); // Run this effect whenever showPopup changes

    const handleClose = () => {
        setShowPopup(false);
    };

    const handleUserName = (e) => {
        setUserName(e.target.value);
    };

    const handleUserMobile = (e) => {
        setUserMobile(e.target.value);
    };

    const handleOnlyDigits = (e) => {
        // Allow only digits (0-9)
        if (
            !(
                // Digits
                (e.keyCode >= 48 && e.keyCode <= 57) ||
                (e.keyCode >= 96 && e.keyCode <= 105) ||
                // Control keys
                e.keyCode === 8 || // Backspace
                e.keyCode === 46 || // Delete
                e.keyCode === 9 || // Tab
                e.keyCode === 13 // Enter
            )
        ) {
            e.preventDefault();
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission, for example, send data to backend or store in localStorage
        // After submission, you might want to close the popup
        if (userName !== '' && userMobile !== '') {
            localStorage.setItem('user_name', userName);
            localStorage.setItem('user_mobile', userMobile);

            setShowPopup(false);
        }
    };

    return (
        <div>
            {showPopup && (
                <div className="popup container mal-text">
                    <div className="popup-content">
                        <div className="close-btn">
                            <button onClick={handleClose} type="button" className="btn-close" aria-label="Close"></button>
                        </div>
                        <h4>ekmcart.com</h4>
                        <p>ഞങ്ങൾ പറവൂർ മുൻസിപ്പൽ പരിധിയിൽ വീട്ടു സാധങ്ങൾ ഹോം ഡെലിവറി ചെയ്യുന്നു. പുതിയ ഓഫറുകൾക്കായി ജോയിൻ ചെയ്യൂ.</p>
                        <p></p>
                        <div className="user_name">
                            <label className='form-label mal-text'>Name | പേര്</label>
                            <input
                                type="text"
                                name="name"
                                className='form-control'
                                value={userName}
                                onChange={handleUserName}
                            />
                        </div>
                        <div className="user_mobile">
                            <label className='form-label mal-text'> Mobile Number | മൊബൈൽ നമ്പർ  </label>
                            <input
                                type="text"
                                name="mobileNumber"
                                className='form-control'
                                value={userMobile}
                                onChange={handleUserMobile}
                                onKeyDown={handleOnlyDigits}
                            />
                        </div>
                        <div className="mt-3 mb-2 d-grid gap-2">
                            <button onClick={handleSubmit} className="btn btn-success">Submit</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default UserDataCollectPopup
