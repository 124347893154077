import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteCart } from '../store/slices/cartSlice';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import useRazorpay from "react-razorpay";

import Header from '../components/Header'
import Footer from '../components/Footer'
import MobileFooter from '../components/MobileFooter'

const PaymentPage = () => {
    const siteData = useSelector(state => state.site.data);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [orderData, setOrderData] = useState([])
    const [Razorpay] = useRazorpay();
    const apiKey = siteData.RZP_KEY

    useEffect(() => {
        const last_order = JSON.parse(localStorage.getItem('last_order'));
        setOrderData(last_order);
    }, []);

    const handleDeleteCart = () => {
        dispatch(deleteCart());
    };

    const handleClick = (e) => {
        var options = {
            key: apiKey, //"rzp_test_cSNXDewzQRQTtR", // razorpay key id
            amount: orderData.amount,// 100, // amount
            currency: "INR",
            name: "EKM CART",
            description: "Test Transaction for EKM CART",
            image: "/logo512.png",
            order_id: orderData.payment_reference_id,
            handler: async function (response) {
                console.log(response.razorpay_payment_id);
                console.log(response.razorpay_order_id);
                console.log(response.razorpay_signature);

                handleDeleteCart();

                const parameters = {
                    payment_reference: response.razorpay_payment_id,
                    payment_reference_id: response.razorpay_order_id
                }
                try {
                    const response = await axios.post('https://ekmcart.com/apis/successPayment.php', parameters, {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    });
                    const responseData = response.data;
                    if (responseData.success) {
                        console.log("responseData", responseData);
                        navigate(`/orders/${orderData.order_id}`);
                    } else {
                        console.log("responseData", responseData);
                    }
                } catch (error) {
                    console.log("error", error);
                }
            },
            prefill: {
                name: localStorage.getItem('user_name'),
                contact: localStorage.getItem('user_mobile'),
            },
            notes: {
                address: localStorage.getItem('address_house') + ', ' + localStorage.getItem('address_location'),
            },
            theme: {
                color: "#3399cc",
            },
        };
        var rzp1 = new Razorpay(options);
        rzp1.on('payment.failed', function (response) {
            alert(response.error.code);
            alert(response.error.description);
            alert(response.error.source);
            alert(response.error.step);
            alert(response.error.reason);
            alert(response.error.metadata.order_id);
            alert(response.error.metadata.payment_id);
        });

        rzp1.open();
        e.preventDefault();
    };
    return (
        <>
            <section className="mb-5 payment page">
                <Header title={"Payments"} shown={true} />
                <div className='card container'>
                    <h6 className='pt-2'>Home delivery in North paravur</h6>
                    <p className='mal-text '>നോർത്ത് പറവൂർ മുൻസിപ്പൽ പരിധിയിൽ , എല്ലാ വീട്ടു സാധനങ്ങളും ഹോം ഡെലിവറി ചെയ്യുന്നു. 6 മുതൽ 24 മണിക്കൂറിൽ ഉള്ളിൽ ഡെലിവറി ചെയ്യുന്നു. റീട്ടേൻസ് കണ്ടിഷൻസ് വിദേയമാണ്. <label className='link text-primary' onClick={() => navigate("/terms&condition")}>Terms and Conditions</label> </p>
                </div>

                <div className="mb-5 w-100 gap-2 bottom-0 fixed mb-1">
                    <div class="delivery-time-banner_delivery-banner-container">
                        <h5 class="delivery-label">Delivering to you in
                            <span class="font-heading"> soon</span>
                        </h5>
                    </div>
                    <div className="container p-3">
                        <div className="card mb-2">
                            <div className="row p-2">
                                <div className="col-6">
                                    <label>Order ID: </label>
                                </div>
                                <div className="col-6">
                                    <label>{orderData.order_id}</label>
                                </div>

                                <div className="col-6">
                                    <label>Amount: </label>
                                </div>
                                <div className="col-6">
                                    <label>₹{orderData.amount ? parseFloat(orderData.amount).toFixed(2) : '0.00'}</label>
                                </div>

                                <div className="col-6">
                                    <label>You Save: </label>
                                </div>
                                <div className="col-6">
                                    <label>₹{orderData.you_save ? parseFloat(orderData.you_save).toFixed(2) : '0.00'}</label>
                                </div>

                            </div>
                        </div>
                        <div class="d-grid ">
                            <button onClick={handleClick} id="rzp-button" className="btn btn-success">Pay ₹{parseFloat(orderData.amount).toFixed(2)}</button>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
            <MobileFooter />
        </>
    )
}

export default PaymentPage
